import {
    MdConveyorBelt,
    MdFastfood,
    MdOutlineShoppingCart,
    MdPallet,
    MdPayment,
} from "react-icons/md";

function Default(props: { maincard: JSX.Element }) {
    const { maincard } = props;
    return (
        <div className="relative bg-[#fedd659c] h-screen w-full grid grid-cols-12 no-scrollbar">
            <div className="hidden md:block col-span-6 bg-[#FEDE65] rounded-sm h-full">
                <p className="mt-[33%] ml-5 text-[45px] font-han px-[30px]">
                    Faites revenir vos clients en boutique
                </p>
                <p className="mt-5 ml-5 text-xl px-10">
                    Installation en 5 minutes top chrono ! Pourquoi ne pas
                    essayer ?
                </p>
                {/* Top */}
                <div className="absolute top-[10%] left-[6%] text-white transform rotate-12 opacity-30">
                    <MdPayment size="60" className="ml-[15%] mt-10" />
                </div>
                <div className="absolute top-[1%] left-[27%] text-white transform rotate-12 opacity-30">
                    <MdPallet size="60" className="ml-[15%] mt-10" />
                </div>
                <div className="absolute top-[16%] left-[17%] text-white transform -rotate-6 opacity-30">
                    <MdOutlineShoppingCart
                        size="60"
                        className="ml-[15%] mt-10"
                    />
                </div>
                <div className="absolute top-[17%] left-[38%] text-white transform -rotate-6 opacity-30">
                    <MdConveyorBelt size="60" className="ml-[15%] mt-10" />
                </div>
                {/* Bottom */}
                <div className="absolute top-[70%] left-[4%] text-white transform rotate-12 opacity-30">
                    <MdOutlineShoppingCart
                        size="60"
                        className="ml-[15%] mt-10"
                    />
                </div>
                <div className="absolute top-[75%] left-[25%] text-white transform rotate-12 opacity-30">
                    <MdConveyorBelt size="60" className="ml-[15%] mt-10" />
                </div>
                <div className="absolute top-[52%] left-[30%] text-white transform rotate-12 opacity-30">
                    <MdFastfood size="60" className="ml-[15%] mt-10" />
                </div>
                <div className="absolute top-[55%] left-[14%] text-white transform -rotate-6 opacity-30">
                    <MdPallet size="60" className="ml-[15%] mt-10" />
                </div>
                <div className="absolute top-[60%] left-[38%] text-white transform -rotate-6 opacity-30">
                    <MdPayment size="60" className="ml-[15%] mt-10" />
                </div>
                <p className="ml-2 absolute bottom-1 text-white text-sm">
                    ©{new Date().getFullYear()} Fidiz. All Rights Reserved.{" "}
                </p>
            </div>
            <div className="col-span-12 md:col-span-6 md:ml-10">{maincard}</div>
        </div>
    );
}

export default Default;
