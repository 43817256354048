import LiveChatFooter from "components/helper/LiveChatFooter";
import { getCampaigns } from "interfaces/campaign";
import { useEffect, useState } from "react";
import { FaRepeat } from "react-icons/fa6";
import { MdAnalytics } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dateToFrench } from "utils/date";
import CampaignMenu from "views/email-model/component/CampaignMenu";

// const ModuleCard = (props: {
//     logo: any;
//     name: string;
//     service: string;
//     description: string;
//     url: string;
//     gradient: string;
//     btnColor: string;
// }) => {
//     const { name, gradient, btnColor } = props;

//     return (
//         <div
//             className="relative w-full mt-2 cursor-default shadow-lg border-2 border-[#1f2937] rounded-xl"
//             onClick={() =>
//                 toast.info(
//                     "Vous n'avez pas assez de données pour lancer une campagne",
//                 )
//             }
//         >
//             <div className="absolute top-3 right-3 bg-[#1f2937] text-white text-xs px-2 py-1 rounded-lg">
//                 <p className="font-han">150 tokens</p>
//             </div>
//             <div
//                 className={`px-2 py-4 border bg-gradient-to-r ${gradient} rounded-t-lg flex items-center justify-center`}
//             >
//                 <p className="my-10 align-middle text-center text-xl font-han">
//                     {name}
//                 </p>
//             </div>
//             {/* Badge */}
//             {/* <div className="absolute top-3 right-3 bg-green-600/80 text-white text-xs px-2 py-1 rounded-lg">
//                 {"Gratuit"}
//             </div> */}
//             {/* Header */}
//             <div className="py-3 w-full px-4 rounded-b-lg border shadow">
//                 <div className="mt-2 grid grid-cols-2 gap-4">
//                     <div
//                         className={`p-1 px-3 rounded-xl flex gap-3 border-2 border-[#1f2937] bg-gradient-to-r ${gradient}`}
//                     >
//                         <p className="flex my-auto font-bold">10%</p>
//                         <div className="h-full w-1 bg-[#1f2937] rounded-full"></div>
//                         <p className="flex my-auto text-xs">Retour boutique</p>
//                     </div>
//                     <div
//                         className={`p-1 px-3 rounded-xl flex gap-3 border-2 border-[#1f2937] bg-gradient-to-r ${gradient}`}
//                     >
//                         <p className="flex my-auto font-bold">115</p>
//                         <div className="h-full w-1 bg-[#1f2937] rounded-full"></div>
//                         <p className="flex my-auto mx-auto text-xs">
//                             Clients impactés
//                         </p>
//                     </div>
//                 </div>
//                 <button
//                     className={`mb-4 linear mt-6 w-full rounded-xl ${btnColor} border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:opacity-70 active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
//                 >
//                     Lancer cette campagne
//                 </button>
//             </div>
//         </div>
//     );
// };

const Marketing = () => {
    const nav = useNavigate();
    const [campaigns, setCampaigns] = useState([]);

    const initCampaigns = async () => {
        const res = await getCampaigns();
        if (res) setCampaigns(res.data);
    };

    const openCrispChat = () => {
        const crispId = "9adcc5aa-0ea5-46ef-b687-0d9e9b8e4aa7";
        const crispBaseUrl = "https://go.crisp.chat/chat/embed/?website_id=";

        window.open(`${crispBaseUrl}${crispId}`, "_blank");
    };

    useEffect(() => {
        initCampaigns();
    }, []);

    return (
        <div className="">
            <div className="relative mt-1 grid grid-cols-1 gap-4">
                <div className="rounded-xl p-5 bg-gradient-to-r from-[#d4adfe58] to-[#d4adfe39] shadow-lg">
                    <div className="">
                        <h1 className="text-sm font-bold">
                            🎯 Vous souhaitez déléguer la gestion de vos
                            campagnes ?
                        </h1>
                        <p className="mt-2 p-1 text-sm">
                            Fidiz Studio est notre offre complète de gestion de
                            vos campagnes marketing et de vos réseaux sociaux.
                            Nous nous occupons de tout pour vous, grâce à nos
                            outils et notre expertise.
                        </p>
                        <div className="flex justify-end ml-10">
                            <div
                                className="flex gap-3 my-auto"
                                onClick={openCrispChat}
                            >
                                <p className="text-xs text-center font-medium text-blue-600 cursor-pointer">
                                    Échanger avec un expert
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 flex justify-between">
                <div className="mt-2">
                    <CampaignMenu path="/marketing" />
                </div>
                <button
                    className={`p-5 linear rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                    onClick={() => nav("/campaign/new")}
                >
                    Ajouter une campagne
                </button>
            </div>
            {/* <div className="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-4">
                <ModuleCard
                    name="Clients qui ne sont pas venus depuis 1 mois"
                    service="crm"
                    description="Gérez vos clients et vos prospects, planifiez vos rendez-vous."
                    logo={"📞"}
                    url="/kanban"
                    gradient="from-[#d4adfe] to-[#d4adfe9b]"
                    btnColor="bg-[#d4adfe]"
                />
                <ModuleCard
                    name="Clients ayant assez de points disponibles"
                    service="shop-b2b"
                    description="Gérez vos clients et vos prospects, planifiez vos rendez-vous."
                    logo={"🛍️"}
                    url="/settings/command-page"
                    gradient="from-talk-500 to-[#fedd6597]"
                    btnColor="bg-talk-500"
                />
                <ModuleCard
                    name="Clients assidus qui ne sont pas venus depuis 1 mois"
                    service="delivery"
                    description="Gérez vos clients et vos prospects, planifiez vos rendez-vous."
                    logo={"🚚"}
                    url="/delivery/delivery-list?f=1"
                    gradient="from-[#f1e9f8] to-[#f1e9f8b2]"
                    btnColor="bg-[#f1e9f8]"
                />
            </div> */}
            {/* List campaigns */}
            {campaigns && campaigns.length > 0 && (
                <>
                    <div className="mt-5 hidden sm:grid grid-cols-12 px-5 w-full text-sm font-medium">
                        <p className="col-span-2">Date</p>
                        <p className="col-span-3">Template</p>
                        <p className="col-span-3">Ciblage client</p>
                        <p className="col-span-2">Clients contactés</p>
                        <p className="col-span-2">Action</p>
                    </div>
                    <div className="mt-1 grid grid-cols-1 gap-3">
                        {campaigns.map((campaign: any, index: number) => (
                            <div className="grid grid-cols-1 sm:grid-cols-12 w-full border rounded-xl p-4 gap-4">
                                <p className="col-span-2">
                                    {dateToFrench(campaign.createdAt)}
                                </p>
                                <div className="col-span-3 text-sm">
                                    <p>{campaign.templateName}</p>
                                </div>
                                <p className="col-span-3 text-sm">
                                    {campaign.targetName}
                                </p>
                                <p className="hidden sm:flex col-span-3 sm:col-span-2">
                                    {campaign.totalCustomers}
                                </p>
                                <div className="col-span-2 flex gap-4">
                                    <FaRepeat
                                        className="cursor-not-allowed"
                                        title="Relancer cette campagne"
                                    />
                                    <MdAnalytics
                                        className="cursor-not-allowed"
                                        title="Analyser cette campagne"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {/* No campaigns created */}
            {campaigns && campaigns.length === 0 && (
                <>
                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-han text-center">
                            Il est temps de lancer une campagne !
                        </p>
                        <p className="flex mx-auto mt-5 text-center max-w-[550px]">
                            Construisez une campagne sur mesure à partir d'un
                            template email ou SMS et d'un segment client,
                            parfait pour faire parvenir le bon message aux
                            bonnes personnes
                        </p>
                        <button
                            className={`mt-5 flex mx-auto p-5 linear rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-sm transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={() => nav("/campaign/new")}
                        >
                            Ajouter une campagne
                        </button>
                    </div>
                </>
            )}
            <LiveChatFooter />
        </div>
    );
};

export default Marketing;
