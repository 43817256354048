import { Routes, Route } from "react-router-dom";

import RTLLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import Access from "layouts/access";
//import Hotjar from "@hotjar/browser";
import OrderSuccess from "views/admin/main/ecommerce/productPage/components/OrderSuccess";
import UiKit from "views/ui-kit";
import ScrollToTop from "components/router/scrollToTop";
import Delivery from "views/external/hybride-mobile";
import CommingSoon from "views/comming-soon";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginError from "views/login-with-qr";
import PresentationPage from "views/external/presentation";
import PhoneAuthentication from "views/external/PhoneAuthentication";
import OnboardingStepOne from "views/onboarding/onboardingStepOne";
import OnboardingStepTwo from "views/onboarding/onboardingStepTwo";
import OnboardingStepThree from "views/onboarding/onboardingStepThree";
import AutonomousMobile from "views/external/autonomous-mobile";
import GoogleFeedback from "views/external/googleFeedback/Feedback";
import OnboardingTestingStep from "views/onboarding/onboardingTestingStep";
import GameWheel from "views/external/game/wheel";
import Affiliate from "views/external/affiliation/Affiliate";
import AskADemo from "views/auth/AskADemo/AskADemo";
import Page from "views/external/page";
import Audit from "views/external/audit";
import AuditLanding from "views/external/audit/auditLanding";
import AuditStepTwo from "views/external/audit/onboardingStepOne";
import AuditStepThree from "views/external/audit/onboardingStepThree";
import AuditStepFour from "views/external/audit/onboardingStepFour";
import AuditStepFive from "views/external/audit/onboardingStepFive";
import AuditFinal from "views/external/audit/auditFinal";
import AuditResult from "views/external/audit/auditResult";
import Whatsapp from "views/external/whatsapp";

//Hotjar.init(3583940, 6);
const isMaintenance = false;

const App = () => {
    return (
        <GoogleOAuthProvider clientId="259712515082-77a0p2i9e8p4khd4ca748peh7ccvg8sr.apps.googleusercontent.com">
            <div>
                {isMaintenance && <CommingSoon />}
                {!isMaintenance && <ScrollToTop />}
                {!isMaintenance && (
                    <Routes>
                        {/* Redirect all trafic to maintenance page */}
                        {/* Internal application */}
                        <Route path="auth/*" element={<AuthLayout />} />
                        <Route path="admin/*" element={<AdminLayout />} />
                        <Route path="/*" element={<AdminLayout />} />
                        <Route path="rtl/*" element={<RTLLayout />} />
                        <Route path="/" element={<Access />} />

                        {/* Dev */}
                        <Route path="ui-kit/" element={<UiKit />} />

                        {/* Talk pages */}
                        <Route path="/success" element={<OrderSuccess />} />

                        {/* Externals mobiles experiences */}

                        <Route path="/demo" element={<AskADemo />} />

                        <Route path="/audit" element={<AuditLanding />} />
                        <Route path="/audit/1" element={<Audit />} />
                        <Route path="/audit/2" element={<AuditStepTwo />} />
                        <Route path="/audit/3" element={<AuditStepThree />} />
                        <Route path="/audit/4" element={<AuditStepFour />} />
                        <Route path="/audit/5" element={<AuditStepFive />} />
                        <Route path="/audit/final" element={<AuditFinal />} />
                        <Route path="/audit/result" element={<AuditResult />} />

                        {/* Hybride: points per €, token is required to get points */}
                        <Route path="/access/:id" element={<Delivery />} />
                        {/* Autonomous: 1 point per visit, no token required, geoloc activated */}
                        <Route path="/page/:slug" element={<Page />} />
                        <Route
                            path="/access-auto/:id"
                            element={<AutonomousMobile />}
                        />

                        <Route path="/game-wheel/:id" element={<GameWheel />} />

                        <Route
                            path="/feedback/:id/:placeId"
                            element={<GoogleFeedback />}
                        />

                        <Route path="/affiliate/:id" element={<Affiliate />} />

                        <Route
                            path="/account/:id"
                            element={<PhoneAuthentication />}
                        />
                        <Route
                            path="/presentation"
                            element={<PresentationPage />}
                        />
                        <Route
                            path="/1/onboarding"
                            element={<OnboardingStepOne />}
                        />
                        <Route
                            path="/2/onboarding"
                            element={<OnboardingStepTwo />}
                        />
                        <Route
                            path="/3/onboarding"
                            element={<OnboardingStepThree />}
                        />
                        <Route
                            path="/4/onboarding"
                            element={<OnboardingTestingStep />}
                        />

                        <Route path="/login-error" element={<LoginError />} />
                        <Route path="/wa" element={<Whatsapp />} />
                    </Routes>
                )}
            </div>
        </GoogleOAuthProvider>
    );
};

export default App;
