import { MdEuro, MdOutlineShoppingBasket } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TbMoneybag, TbPigMoney, TbTrash } from "react-icons/tb";

import MiniStatistics from "components/card/MiniStatistics";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { BiArrowFromLeft, BiPlusCircle, BiRightArrow } from "react-icons/bi";
import { getMyRestaurant, updateMyRestaurant } from "interfaces/restaurant";
import SaveStatus from "components/actions/SaveStatus";
import { getMyOnlyRestaurantCustomers } from "interfaces/customer";
import LiveChatFooter from "components/helper/LiveChatFooter";
import { FaArrowRight } from "react-icons/fa";

const Dashboard = () => {
    const navigate = useNavigate();
    const [saveStatus, setSaveStatus] = useState<string>("none");
    const page = 1;
    const limit = 5;
    const [customersCount, setCustomersCount] = useState<number>(0);
    const [restaurant, setRestaurant] = useState<any>({
        rewards: [],
        googlePlaceId: null,
    });

    const handleChange = (e: any) => {
        setRestaurant({
            ...restaurant,
            [e.target.id]: e.target.value,
        });
    };

    const handleRewardChange = (e: any, index: number) => {
        const rewards = restaurant.rewards;
        rewards[index][e.target.id.split("-")[0]] = e.target.value;
        setRestaurant({
            ...restaurant,
            rewards,
        });
    };

    const initRestaurant = async () => {
        const restaurant = await getMyRestaurant();
        setRestaurant(restaurant);
    };

    useEffect(() => {
        if (restaurant === null) return;
        if (restaurant.name === "") return;
        setSaveStatus("saving");
        const delayDebounceFn = setTimeout(async () => {
            const res = await updateMyRestaurant(restaurant);
            if (res) setSaveStatus("saved");
            else setSaveStatus("error");
        }, 1500);
        return () => clearTimeout(delayDebounceFn);
    }, [restaurant]);

    const initMyCustomers = async () => {
        const customers: any = await getMyOnlyRestaurantCustomers(page, limit);
        if (!customers) return;
        setCustomersCount(customers.total);
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) navigate("/auth/login");
        initMyCustomers();
        initRestaurant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 flex h-full w-full flex-col gap-[20px] rounded-[20px] sm:mt-3 xl:flex-row">
                <div className="h-full w-full rounded-[20px]">
                    <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                        {/* Statistics */}
                        <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-5 md:grid-cols-2 xl:grid-cols-4">
                            <div>
                                <MiniStatistics
                                    name="Mes restaurants"
                                    value={"1"}
                                    icon={<TbMoneybag color="#2d3a42" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div id="step-one">
                                <MiniStatistics
                                    name="Clients"
                                    value={`${customersCount}`}
                                    icon={
                                        <MdOutlineShoppingBasket color="#2d3a42" />
                                    }
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Récurrence"
                                    value={"-"}
                                    icon={<MdEuro color="#2d3a42" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                            <div>
                                <MiniStatistics
                                    name="Total points"
                                    value={"-"}
                                    icon={<TbPigMoney color="#2d3a42" />}
                                    iconBg="bg-lightPrimary"
                                />
                            </div>
                        </div>
                        {/* Main data */}
                        <div className="mt-3 flex justify-end">
                            <SaveStatus status={saveStatus} />
                        </div>
                        <div className="mt-1 grid w-full grid-cols-8 gap-5">
                            <div className="col-span-12">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                                    <div className="col-span-1">
                                        <Card
                                            extra={
                                                "relative flex px-5 w-full text-4xl border"
                                            }
                                        >
                                            <h4 className="mt-4 text-xl font-medium">
                                                Mon enseigne
                                            </h4>
                                            <p className="mt-1 text-sm">
                                                Informations utiles sur votre
                                                enseigne.
                                            </p>
                                            <div className="mt-5 flex gap-5">
                                                <InputField
                                                    id="name"
                                                    label="Nom de l'enseigne"
                                                    placeholder="Le snack de la gare"
                                                    value={restaurant?.name}
                                                    type="text"
                                                    onChange={handleChange}
                                                    inputSmall={true}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <InputField
                                                    id="googlePlaceId"
                                                    label="Lien d'avis Google"
                                                    placeholder="https://g.page/r/TRRA0_ofrT5BEMM/review"
                                                    value={
                                                        restaurant?.googlePlaceId
                                                    }
                                                    type="text"
                                                    onChange={handleChange}
                                                    inputSmall={true}
                                                    extra="w-[310px]"
                                                />
                                                {restaurant?.googlePlaceId ? (
                                                    <p
                                                        className="ml-1 mt-1 text-xs text-blue-500 cursor-pointer mb-3"
                                                        onClick={() =>
                                                            window.open(
                                                                `https://app.fidiz.io/access/${restaurant?._id}`,
                                                                "_blank",
                                                            )
                                                        }
                                                    >
                                                        Tester en tant que
                                                        client
                                                    </p>
                                                ) : (
                                                    <p
                                                        className="ml-1 mt-1 text-xs text-blue-500 cursor-pointer mb-3"
                                                        onClick={() =>
                                                            window.open(
                                                                "https://fidiz.io/comment-booster-ses-avis-sur-google-et-etre-n1/",
                                                                "_blank",
                                                            )
                                                        }
                                                    >
                                                        Comment trouver ce lien
                                                        ?
                                                    </p>
                                                )}
                                            </div>
                                        </Card>
                                        <Card
                                            extra={
                                                "mt-3 relative flex px-5 w-full text-4xl border"
                                            }
                                        >
                                            <div className="absolute top-5 right-5">
                                                <p
                                                    className="text-xs cursor-pointer text-blue-500 hover:underline"
                                                    onClick={() =>
                                                        setRestaurant({
                                                            ...restaurant,
                                                            affiliation:
                                                                !restaurant.affiliation,
                                                        })
                                                    }
                                                >
                                                    {restaurant.affiliation
                                                        ? "Désactiver"
                                                        : "Activer"}
                                                </p>
                                            </div>
                                            <div className="flex mt-4 gap-2">
                                                <h4 className="text-xl font-medium">
                                                    Affiliation{" "}
                                                </h4>
                                                {restaurant.affiliation ? (
                                                    <p className="border p-1 px-2 rounded-full border-green-500 flex my-auto text-xs text-green-500">
                                                        Activé
                                                    </p>
                                                ) : (
                                                    <p className="border p-1 px-2 rounded-full border-red-500 flex my-auto text-xs text-red-500">
                                                        Désactivé
                                                    </p>
                                                )}
                                            </div>
                                            <p className="mt-1 text-sm">
                                                Mettez en place un système de
                                                parrainage pour vos clients.
                                            </p>
                                            <div className="">
                                                <label
                                                    htmlFor={"label-msg"}
                                                    className={`text-navy-700 text-xs ml-1.5 font-medium`}
                                                >
                                                    Message de parrainage
                                                </label>
                                                <textarea
                                                    id="sponsorMessage"
                                                    className="w-full h-[45px] text-xs px-2 py-1 focus:outline-none focus:ring rounded-xl border-xl border transition-colors duration-300"
                                                    placeholder="Contenue du SMS"
                                                    onChange={handleChange}
                                                    value={
                                                        restaurant?.sponsorMessage
                                                    }
                                                ></textarea>
                                            </div>
                                            <div className="mt-2 mb-3 flex gap-10">
                                                <InputField
                                                    id={`sponsorGiftPoints`}
                                                    label="Points récompense parrain"
                                                    placeholder="0"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={
                                                        restaurant?.sponsorGiftPoints
                                                    }
                                                    inputSmall={true}
                                                />
                                                <p className="flex my-auto text-base">
                                                    +
                                                </p>
                                                <InputField
                                                    id={`sponsorGift`}
                                                    label="Cadeau parrain"
                                                    placeholder="Un menu offert"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={
                                                        restaurant?.sponsorGift
                                                    }
                                                    inputSmall={true}
                                                />
                                            </div>
                                            <div className="mt-2 mb-3 flex gap-10">
                                                <InputField
                                                    id={`affiliateGiftPoints`}
                                                    label="Points récompense filleul"
                                                    placeholder="0"
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={
                                                        restaurant?.affiliateGiftPoints
                                                    }
                                                    inputSmall={true}
                                                />
                                                <p className="flex my-auto text-base">
                                                    +
                                                </p>
                                                <InputField
                                                    id={`affiliateGift`}
                                                    label="Cadeau filleul"
                                                    placeholder="Un menu offert"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={
                                                        restaurant?.affiliateGift
                                                    }
                                                    inputSmall={true}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                    <Card
                                        extra={
                                            "relative flex px-5 h-[580px] w-full text-4xl border"
                                        }
                                    >
                                        <h4 className="mt-4 text-xl font-medium">
                                            Récompenses
                                        </h4>
                                        <p className="mt-1 mb-4 text-sm">
                                            Récompenses disponibles en fonction
                                            du nombre de points cumulés.
                                        </p>
                                        <div className="overflow-y-scroll">
                                            {restaurant &&
                                                restaurant.rewards.map(
                                                    (
                                                        reward: any,
                                                        index: number,
                                                    ) => (
                                                        <div className="my-3 flex gap-5">
                                                            <InputField
                                                                id={`name-${index}`}
                                                                label="Récompense"
                                                                placeholder="Un menu offert"
                                                                type="text"
                                                                onChange={(
                                                                    e: any,
                                                                ) =>
                                                                    handleRewardChange(
                                                                        e,
                                                                        index,
                                                                    )
                                                                }
                                                                value={
                                                                    reward?.name
                                                                }
                                                                inputSmall={
                                                                    true
                                                                }
                                                            />
                                                            <InputField
                                                                id={`points-${index}`}
                                                                label="Nombre de points nécessaires"
                                                                placeholder="0"
                                                                type="number"
                                                                onChange={(
                                                                    e: any,
                                                                ) =>
                                                                    handleRewardChange(
                                                                        e,
                                                                        index,
                                                                    )
                                                                }
                                                                value={
                                                                    reward?.points
                                                                }
                                                                inputSmall={
                                                                    true
                                                                }
                                                            />
                                                            <TbTrash
                                                                size="25"
                                                                className="text-red-500 border rounded-xl p-1 cursor-pointer"
                                                                onClick={() => {
                                                                    setRestaurant(
                                                                        {
                                                                            ...restaurant,
                                                                            rewards:
                                                                                restaurant.rewards.filter(
                                                                                    (
                                                                                        r: any,
                                                                                        i: number,
                                                                                    ) =>
                                                                                        i !==
                                                                                        index,
                                                                                ),
                                                                        },
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    ),
                                                )}
                                        </div>
                                        <div
                                            className="flex mt-4 mb-4 w-[220px] text-sm p-1 gap-2 cursor-pointer border rounded-xl"
                                            onClick={() => {
                                                setRestaurant({
                                                    ...restaurant,
                                                    rewards: [
                                                        ...restaurant.rewards,
                                                        {
                                                            name: "",
                                                            points: 0,
                                                        },
                                                    ],
                                                });
                                            }}
                                        >
                                            <p className="ml-3 text-sm">
                                                Ajouter une récompense
                                            </p>
                                            <BiPlusCircle
                                                size="20"
                                                className="text-[#bfdce6]"
                                            />
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LiveChatFooter />
        </>
    );
};

export default Dashboard;
