import GoBack from "components/actions/GoBack";
import Select from "components/fields/Select";
import { getMyCustomersFiltered } from "interfaces/customer";
import Pagination from "components/pagination/Pagination";
import { getTarget, updateTarget } from "interfaces/target";
import { useEffect, useState } from "react";

import { IoAddCircleOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { dateToFrenchShort } from "utils/date";
import { MdOutlineCancel } from "react-icons/md";

const EditCustomersLists = () => {
    const nav = useNavigate();
    const params = useParams();
    const [target, setTarget] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [newCriteria, setNewCriteria] = useState({
        field: "points",
        operator: ">=",
        value: 5,
    });
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(5);
    const [criteria, setCriteria] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);

    const handleChange = (e: any) => {
        setTarget({ ...target, [e.target.id]: e.target.value });
    };

    const handleCriteriaChange = (e: any) => {
        setNewCriteria({ ...newCriteria, [e.target.id]: e.target.value });
    };

    const initTarget = async () => {
        const target = await getTarget(params.id);
        if (target) {
            setTarget(target);
            setCriteria(target.criteria);
        }
    };

    const saveTemplate = async () => {
        const res = await updateTarget(params.id, {
            name: target.name,
            criteria: target.criteria,
            totalCustomers: totalUsers,
        });
        if (res) nav("/customers-lists");
    };

    const fetchCustomers = async (page: number, limit: number) => {
        if (!target) return;
        const res = await getMyCustomersFiltered(target.criteria, page, limit);
        if (res) {
            setCustomers(res.data);
            setTotalUsers(res.total);
            setPage(page);
            setLimitPerPage(limit);
        }
    };

    useEffect(() => {
        fetchCustomers(1, limitPerPage);
    }, [criteria]);

    useEffect(() => {
        initTarget();
    }, []);

    return (
        <>
            <GoBack text="Mes ciblages client" />
            <div className="mt-2 relative border-2 p-5 border-[#1f2937] rounded-xl">
                <div className="flex justify-between">
                    <p className="text-xl font-han">
                        Liste de {totalUsers} clients
                    </p>
                    <div className="flex gap-1">
                        <div className="flex">
                            <input
                                id="name"
                                key={"name"}
                                type="text"
                                placeholder="Nom du template"
                                value={target?.name}
                                className="p-2 w-[200px] h-[30px] rounded-xl border-xl border-2 border-[#1f2937]"
                                onChange={handleChange}
                            />
                        </div>
                        <button
                            className={`p-2 linear h-[30px] rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={saveTemplate}
                        >
                            Sauvegarder le ciblage
                        </button>
                    </div>
                </div>
                {/* Create new conditions */}
                <div className="mt-3 flex gap-4 justify-between">
                    <div className="flex gap-1">
                        <Select
                            key={"field"}
                            options={[
                                {
                                    value: "points",
                                    label: "Points de fidélité",
                                    id: 1,
                                },
                                {
                                    value: "lastScan",
                                    label: "Dernier scan (jours)",
                                    id: 2,
                                },
                            ]}
                            displayKey="label"
                            onSelect={(e: any) =>
                                handleCriteriaChange({
                                    target: {
                                        id: "field",
                                        value: e.value,
                                    },
                                })
                            }
                            value={newCriteria.field}
                            required={false}
                        />
                        <Select
                            key={"operator"}
                            options={[
                                {
                                    value: ">=",
                                    label: "Supérieur ou égal",
                                    _id: 1,
                                },
                                {
                                    value: ">",
                                    label: "Supérieur",
                                    _id: 2,
                                },
                                {
                                    value: "==",
                                    label: "Egal",
                                    _id: 3,
                                },
                                {
                                    value: "!=",
                                    label: "Différent",
                                    _id: 5,
                                },
                                {
                                    value: "<",
                                    label: "Inférieur",
                                    _id: 6,
                                },
                                {
                                    value: "<=",
                                    label: "Inférieur ou égal",
                                    _id: 7,
                                },
                                {
                                    value: "<",
                                    label: "Inférieur",
                                    _id: 8,
                                },
                            ]}
                            displayKey="label"
                            onSelect={(e: any) =>
                                handleCriteriaChange({
                                    target: {
                                        id: "operator",
                                        value: e.value,
                                    },
                                })
                            }
                            value={newCriteria.operator}
                            required={false}
                        />
                        <input
                            id="value"
                            key={"value"}
                            type="text"
                            placeholder="Valeur"
                            value={newCriteria.value}
                            onChange={handleCriteriaChange}
                            className="p-2 w-[200px] h-[30px] rounded-xl border-xl border-2 border-[#1f2937]"
                        />
                        <IoAddCircleOutline
                            className="cursor-pointer text-blue-600"
                            onClick={() => {
                                setTarget({
                                    ...target,
                                    criteria: [
                                        ...target.criteria,
                                        {
                                            ...newCriteria,
                                            id: `${newCriteria.field}${newCriteria.operator}${newCriteria.value}`,
                                        },
                                    ],
                                });
                            }}
                            size={30}
                        />
                    </div>
                </div>
                <div className="mt-2 flex gap-4">
                    <p className="mt-2 underline">Filtres:</p>
                    <div className="flex gap-4 mt-3">
                        {target &&
                            target.criteria.map(
                                (criterion: any, index: number) => (
                                    <div className="flex gap-2 text-xs px-2 rounded-xl border-xl border-2 border-[#1f2937]">
                                        <p className="">
                                            {criterion.field}{" "}
                                            {criterion.operator}{" "}
                                            {criterion.value}
                                        </p>
                                        <MdOutlineCancel
                                            className="cursor-pointer text-red-500"
                                            onClick={() => {
                                                setTarget({
                                                    ...target,
                                                    criteria:
                                                        target.criteria.filter(
                                                            (c: any) =>
                                                                c.id !==
                                                                criterion.id,
                                                        ),
                                                });
                                            }}
                                            size={15}
                                        />
                                    </div>
                                ),
                            )}
                        <p
                            className="flex my-auto text-xs font-medium text-blue-600 cursor-pointer"
                            onClick={() => {
                                fetchCustomers(1, limitPerPage);
                            }}
                        >
                            Actualiser
                        </p>
                    </div>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor={"lastCustomers"}
                        className={`text-sm font-medium text-navy-700`}
                    >
                        Mes clients:
                    </label>
                </div>
                <div className="mt-4 hidden sm:grid grid-cols-12 px-5 w-full text-sm font-medium">
                    <p className="col-span-2">Dernier scan</p>
                    <p className="col-span-3">Client</p>
                    <p className="col-span-3">Dernière action</p>
                    <p className="col-span-2">Total points</p>
                </div>
                <div className="mt-1 grid grid-cols-1 gap-3">
                    {customers &&
                        customers.map((customer: any) => (
                            <div className="grid grid-cols-1 sm:grid-cols-12 w-full border rounded-xl p-4 gap-4">
                                <p className="col-span-2">
                                    {dateToFrenchShort(customer?.lastScan)}
                                </p>
                                <div className="col-span-3">
                                    <p>
                                        {customer?.givenName}{" "}
                                        {customer?.familyName}
                                    </p>
                                    <p className="text-xs">{customer?.email}</p>
                                    <p className="text-xs">{customer?.phone}</p>
                                </div>
                                <p className="col-span-3">
                                    QR scanné
                                    {/* , 2 point crédité */}
                                </p>
                                <p className="hidden sm:flex col-span-3 sm:col-span-2">
                                    {customer?.rewardsPoints}
                                </p>
                                <p className="col-span-3 sm:hidden sm:col-span-2">
                                    {customer?.rewardsPoints} points
                                </p>
                            </div>
                        ))}
                </div>
                <Pagination
                    extra="mt-4"
                    text={false}
                    type="clients"
                    maxItems={totalUsers}
                    itemsPerPage={[5, 10, 20, 50]}
                    page={page}
                    onChange={(page: number, limit: number) => {
                        fetchCustomers(page, limit);
                    }}
                />
            </div>
        </>
    );
};

export default EditCustomersLists;
