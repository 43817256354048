const LiveChatFooter = (props: { extra?: string }) => {
    const { extra } = props;

    const openCrispChat = () => {
        const crispId = "9adcc5aa-0ea5-46ef-b687-0d9e9b8e4aa7";
        const crispBaseUrl = "https://go.crisp.chat/chat/embed/?website_id=";

        window.open(`${crispBaseUrl}${crispId}`, "_blank");
    };

    return (
        <div className={`mt-3 flex justify-center mx-auto gap-2 ${extra}`}>
            <p className="text-xs">Besoin d'aide ?</p>
            <div className="flex gap-3 my-auto" onClick={openCrispChat}>
                <p className="text-xs font-medium text-blue-600 cursor-pointer">
                    Contactez notre chat en ligne
                </p>
            </div>
        </div>
    );
};

export default LiveChatFooter;
