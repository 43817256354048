import axios from "axios";
import { errorHandler } from "./utils";
import env from "variables/config";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const forgotPassword = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(`/auth/forgot-password`, data, {})
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const resetPassword = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(`/auth/reset-password`, data, {})
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const claimPoints = async (
    restaurantId: string,
    sessionToken: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(
            `/user/claim-points`,
            { restaurantId, sessionToken },
            {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("user-token"),
                },
            },
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const claimAutoPoints = async (
    restaurantId: string,
    sessionToken: string,
    coords: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(
            `/user/claim-auto-points`,
            { restaurantId, sessionToken, coords },
            {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("user-token"),
                },
            },
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const removePoints = async (
    id: string,
    points: number,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(
            `/user/remove-points/${id}`,
            { points },
            {
                headers: {
                    Authorization:
                        "Bearer " + localStorage.getItem("user-token"),
                },
            },
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateUserInfo = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put("/user/", data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        })
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const removePointsToUser = async (
    restaurantId: string,
    userId: string,
    points: number,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(
            `/user/remove-points/${restaurantId}/${userId}`,
            { points },
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const addPointsToUser = async (
    restaurantId: string,
    userId: string,
    points: number,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(`/user/add-points/${restaurantId}/${userId}`, { points }, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const LinkSponsor = async (
    userId: string,
    sponsorCode: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(`/user/link-sponsor/${userId}`, { sponsorCode }, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getFile = async (
    name: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .get(`/user/files?fileName=${name}`, config())
        .catch(() => {});
    return res;
};

export const getMyInfo = async (): Promise<{ data: any; error: any }> => {
    const res: any = await axios.get("/user/me", config()).catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getDashboard = async (): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .get("/user/dashboard", config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getMyInfoUser = async (): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .get("/user/me", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        })
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const userFeature = async (
    feature: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(`/user/use/${feature}`, {}, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateOnboardingSettings = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(`/user/onboarding-settings`, data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateMyInfo = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put("/user/", data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const uploadCompanyLogo = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(`/user/upload`, data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getDashboardAnalytics = async (
    startDate: string,
    endDate: string,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .get(
            `/user/dashboard?startDate=${startDate}&endDate=${endDate}`,
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updatePassword = async (
    data: any,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put("/user/password", data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};
