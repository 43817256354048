import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "components/card";
import { createCustomer } from "interfaces/customer";
import InputField from "components/fields/InputField";
import {
    isFormErrored,
    isValidEmail,
    isValidPhoneNumber,
} from "utils/verification";
import GoBack from "components/actions/GoBack";

const CreateCustomer = () => {
    const navigate = useNavigate();
    const [newCustomer, setNewCustomer] = useState<any>({
        rewards: 0,
    });

    const handleChange = (e: any) => {
        setNewCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const addCustomer = async (redirectToList = true) => {
        const validationRules: any = {
            mail: isValidEmail,
            phone: (value: any) => !value || isValidPhoneNumber(value),
        };

        if (isFormErrored(newCustomer, validationRules))
            return toast.error("Veuillez vérifier le formulaire");

        const res = await createCustomer(newCustomer);
        if (res) {
            toast.success("Client ajouté");
            redirectToList
                ? navigate("/my-customers")
                : window.location.reload();
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GoBack text="Mes clients" />
            <div className="mt-2 grid  grid-cols-12 border rounded-lg">
                <div className={`col-span-12`}>
                    <Card extra={"w-full !p-5"}>
                        {/* Header */}
                        <div className="w-full px-[8px]">
                            <h4 className="text-xl font-han text-navy-700 dark:text-white">
                                Ajout d'un client
                            </h4>
                            {/* <p className="mt-4 text-base text-gray-600">
                            Vous pouvez contacter notre équipe pour importer
                            tous vos clients automatiquement.
                        </p> */}
                        </div>
                        {/* inputs */}
                        <div className="mt-5 grid gap-3 grid-cols-12">
                            <p className="col-span-12 text-base">
                                Informations client
                            </p>
                            {/* <div className="col-span-12 lg:col-span-2">
                            <InputField
                                label="Numéro client"
                                placeholder="00012"
                                id="customerId"
                                type="text"
                                onChange={handleChange}
                                error="Numéro de client invalide"
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label="Nom commercial"
                                placeholder="Toola"
                                id="displayName"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label="Raison sociale*"
                                placeholder="Toola"
                                id="company"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div> */}
                            <div className="col-span-12 lg:col-span-4">
                                <InputField
                                    label="Email*"
                                    placeholder="mathieu@toola.eu"
                                    id="mail"
                                    type="text"
                                    onChange={handleChange}
                                    error="Email invalide"
                                    value={newCustomer.mail}
                                    isInvalid={!isValidEmail(newCustomer?.mail)}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-4">
                                <InputField
                                    label="Téléphone"
                                    placeholder="+33678003678"
                                    id="phone"
                                    type="text"
                                    onChange={handleChange}
                                    error="invalide"
                                    value={newCustomer.phone}
                                    isInvalid={
                                        !isValidPhoneNumber(newCustomer.phone)
                                    }
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-4">
                                <InputField
                                    label="Points de fidélité"
                                    placeholder="0"
                                    id="rewards"
                                    type="number"
                                    onChange={handleChange}
                                    error="invalide"
                                    value={newCustomer.rewards}
                                    inputSmall={true}
                                />
                            </div>
                            {/* <div className="col-span-12 lg:col-span-2">
                            <InputField
                                label={`Contact`}
                                placeholder="Mathieu Dupont"
                                id="contact"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div> */}
                            <div className="col-span-12 lg:col-span-12">
                                <InputField
                                    label={`Notes utiles`}
                                    placeholder="N'est pas disponible par téléphone le vendredi"
                                    id="notes"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        {/* full width inputs */}
                        {/* <div className="mt-8 grid  grid-cols-1 gap-3 xl:grid-cols-12">
                        <p className="col-span-12 text-base">
                            Informations de facturation
                        </p>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label="Email de facturation"
                                placeholder="mathieu@toola.eu"
                                id="billingEmail"
                                type="text"
                                onChange={handleChange}
                                error="Email invalide"
                                value={newCustomer.billingEmail}
                                isInvalid={
                                    !isValidEmail(newCustomer.billingEmail)
                                }
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <SearchSelector
                                label="Période de facturation"
                                displayKey="name"
                                options={[
                                    { _id: "flexible", name: "flexible" },
                                    { _id: "10-days", name: "10 jours" },
                                    { _id: "15-days", name: "15 jours" },
                                    { _id: "1-month", name: "1 mois" },
                                ]}
                                onSelect={(e: any) => {
                                    setNewCustomer((prevCustomer: any) => ({
                                        ...prevCustomer,
                                        billingPeriod: e._id,
                                    }));
                                }}
                                inputSmall={true}
                            />
                        </div>
                        <div className="mt-4 col-span-12 lg:col-span-6 lg:mt-0">
                            <InputField
                                label={`Adresse de facturation`}
                                placeholder="Champ de Mars, 5 Av. Anatole France, 75007 Paris, France"
                                value={newCustomer.billingAddress}
                                id="billingAddress"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label={`Numéro de TVA`}
                                placeholder="FR32123456789"
                                id="vatNumber"
                                type="text"
                                onChange={handleChange}
                                error="Numéro de TVA invalide"
                                value={newCustomer.vatNumber}
                                isInvalid={
                                    !isValidVATNumber(newCustomer.vatNumber)
                                }
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label={`Numéro de SIREN`}
                                placeholder="123568941"
                                id="siren"
                                type="text"
                                onChange={handleChange}
                                error="SIREN invalide"
                                value={newCustomer.siren}
                                isInvalid={!isValidSiren(newCustomer.siren)}
                                inputSmall={true}
                            />
                        </div>
                    </div> */}
                        <div className="flex mx-auto">
                            <button
                                className={`p-10 linear mt-10 w-full rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:opacity-70 active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                                onClick={() => addCustomer()}
                            >
                                Ajouter le client
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default CreateCustomer;
