const Select = (props: {
    label?: string;
    options?: any;
    displayKey?: string;
    onSelect?: any;
    value?: string;
    required?: boolean;
    extra?: string;
    disabled?: boolean;
    selected?: string;
}) => {
    const {
        label,
        options,
        displayKey,
        onSelect,
        value,
        required = true,
        disabled,
        selected,
    } = props;
    return (
        <>
            <label
                htmlFor="reason"
                className="text-sm font-medium text-navy-700"
            >
                {label}
                {required && "*"}
            </label>
            <div
                className={`${
                    disabled ? "bg-gray-100" : "bg-white"
                } px-1 w-[200px] h-[30px] rounded-xl border-xl border-2 border-[#1f2937]`}
            >
                <select
                    id="reason"
                    disabled={disabled}
                    className={`w-full rounded-xl border-none ${
                        disabled ? "bg-gray-100" : "bg-white"
                    } text-xs font-medium outline-none`}
                    onChange={(e) => onSelect(JSON.parse(e.target.value))}
                    value={JSON.stringify(
                        options.find((o: any) => o.value === value),
                    )}
                >
                    {options?.map((option: any, index: number) => (
                        <option
                            key={`${option?.[displayKey]}-${index}`}
                            value={JSON.stringify(option)}
                            selected={option.value === selected}
                        >
                            {option?.[displayKey]}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default Select;
