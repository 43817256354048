import GoBack from "components/actions/GoBack";
import { getEmailModel, updateEmailModel } from "interfaces/emailModel";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditSmsModel = () => {
    const emailEditorRef = useRef(null);
    const nav = useNavigate();
    const params = useParams();
    const [emailModel, setEmailModel] = useState(null);

    const handleChange = (e: any) => {
        setEmailModel({ ...emailModel, [e.target.id]: e.target.value });
    };

    const saveTemplate = async () => {
        const email = {
            name: emailModel.name,
            text: emailModel.text,
        };
        const res = await updateEmailModel(params.id, email);
        if (res) nav("/email-model");
    };

    const initEmailModels = async () => {
        const email = await getEmailModel(params.id);
        if (email) {
            setEmailModel(email);
        }
    };

    useEffect(() => {
        initEmailModels();
    }, []);

    return (
        <>
            <GoBack text="Mes templates" />
            <div className="mt-2 relative border-2 p-5 border-[#1f2937] rounded-xl">
                <div className="mt-3 flex justify-between">
                    <div className="flex">
                        <textarea
                            id="text"
                            className="w-[500px] h-[100px] px-4 py-2 focus:outline-none focus:ring rounded-xl border-xl border-2 border-[#1f2937] transition-colors duration-300"
                            placeholder="Contenue du SMS"
                            onChange={handleChange}
                            value={emailModel?.text}
                        ></textarea>
                    </div>
                    <div className="flex gap-4">
                        <div className="flex">
                            <input
                                id="name"
                                key={"name"}
                                type="text"
                                placeholder="Nom du template"
                                value={emailModel?.name}
                                className="p-2 w-[300px] h-[60px] rounded-xl border-xl border-2 border-[#1f2937]"
                                onChange={handleChange}
                            />
                        </div>
                        <button
                            className={`p-2 linear h-[60px] rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={saveTemplate}
                        >
                            Sauvegarder
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditSmsModel;
