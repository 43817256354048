import GoBack from "components/actions/GoBack";
import Select from "components/fields/Select";
import LiveChatFooter from "components/helper/LiveChatFooter";
import { createAutomation } from "interfaces/automation";
import { getEmailModels } from "interfaces/emailModel";
import { useEffect, useState } from "react";
import { IoOpenOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const CreateAutomation = () => {
    const nav = useNavigate();
    const [emailModels, setEmailModels] = useState([]);

    const [newAutomation, setNewCampaign] = useState<any>({
        template: "",
        trigger: "register",
        name: "Inscription",
    });

    const saveAutomation = async () => {
        const res = await createAutomation(newAutomation);
        if (res) {
            nav("/auto-marketing");
        }
    };

    const initEmailModels = async () => {
        const res = await getEmailModels();
        if (res) {
            const emailsModelsOptions = res.data.map((emailModel: any) => {
                return {
                    ...emailModel,
                    value: emailModel._id,
                    label: emailModel.name,
                    id: emailModel.id,
                };
            });
            setEmailModels(emailsModelsOptions);
        }
    };

    useEffect(() => {
        if (!newAutomation.template) {
            setNewCampaign({
                ...newAutomation,
                template: emailModels[0]?.value,
            });
        }
    }, [emailModels, newAutomation]);

    useEffect(() => {
        initEmailModels();
    }, []);

    return (
        <>
            <GoBack text="Mes campagnes" />
            <div className="mt-2 relative border-2 p-5 border-[#1f2937] rounded-xl">
                <div className="grid grid-cols-1 lg:grid-cols-2 ">
                    <div>
                        <div className="flex gap-4">
                            <p className="text-lg font-han">1 - Declencheur</p>
                        </div>
                        <p className="mt-2 ml-1 text-sm">
                            Choisissez l'évènement déclencheur
                        </p>
                        <div className="mt-3 flex gap-3">
                            <Select
                                key={"target"}
                                options={[
                                    {
                                        value: "register",
                                        label: "Inscription au programme de fidélité",
                                    },
                                ]}
                                displayKey="label"
                                onSelect={(e: any) => {
                                    setNewCampaign({
                                        ...newAutomation,
                                        target: e.value,
                                    });
                                }}
                                value={newAutomation.target}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className="mt-5 lg:mt-0">
                        <div className="flex gap-4">
                            <p className="text-lg font-han">
                                1 - Template de ma campagne
                            </p>
                        </div>
                        <p className="mt-2 ml-1 text-sm">
                            Choisissez le template email envoyé au déclenchement
                            de cet évènement
                        </p>
                        <div className="mt-3 flex gap-3">
                            <Select
                                key={"template"}
                                options={emailModels.filter(
                                    (e) => e.type === "email",
                                )}
                                displayKey="label"
                                onSelect={(e: any) =>
                                    setNewCampaign({
                                        ...newAutomation,
                                        template: e.value,
                                    })
                                }
                                value={newAutomation.template}
                                required={false}
                            />
                            <div
                                className="flex gap-3 my-auto"
                                onClick={() =>
                                    window.open("/email-model", "_blank")
                                }
                            >
                                <p className="flex gap-1 text-xs font-medium text-blue-600 cursor-pointer">
                                    Gérer mes templates
                                    <IoOpenOutline
                                        className="flex my-auto"
                                        size={10}
                                    />
                                </p>
                            </div>
                        </div>
                        <p className="ml-3 mt-1 text-xs">
                            Type de campagne:{" "}
                            {
                                emailModels?.find(
                                    (e) => e._id === newAutomation.template,
                                )?.type
                            }
                        </p>
                    </div>
                </div>
                <div className="mt-10 flex col-span-1 lg:col-span-2 gap-2 justify-center">
                    <button
                        className={`p-2 linear h-[40px] rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-2 text-md transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                        onClick={saveAutomation}
                    >
                        Ajouter cette automatisation
                    </button>
                </div>
            </div>
            <LiveChatFooter />
        </>
    );
};

export default CreateAutomation;
