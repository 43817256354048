import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Wheel } from "react-custom-roulette";
import InputField from "components/fields/InputField";
import { IoMdTrash } from "react-icons/io";
import { MdAdd, MdOutlineRestaurantMenu } from "react-icons/md";
import { getMyRestaurant, updateMyRestaurant } from "interfaces/restaurant";
import SaveStatus from "components/actions/SaveStatus";
import QRCode from "react-qr-code";
import { FiPhone } from "react-icons/fi";
import { TbMapSearch } from "react-icons/tb";
import { SiUbereats } from "react-icons/si";
import { getPage, updatePage } from "interfaces/page";
import { toast } from "react-toastify";

const PageManager = () => {
    const [saveStatus, setSaveStatus] = useState("none");
    const [totalProbability, setTotalProbability] = useState(0);
    const [data, setData] = useState([]);
    const [restaurant, setRestaurant] = useState(null);
    const [page, setPage] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const initPage = async () => {
        const res = await getPage();
        if (res) {
            setPage(res);
            setSaveStatus("saved");
        }
    };

    const saveChanges = async () => {
        const res = await updatePage(page);
        if (res) setSaveStatus("saved");
        else {
            setSaveStatus("error");
            toast.error("URL indisponible");
        }
    };

    const handleUrlChange = (e: any) => {
        const key = e.target.id;
        const newUrls = { ...page.urls, [key]: e.target.value };
        setPage({ ...page, urls: newUrls });
    };

    useEffect(() => {
        if (!page) return;
        if (!loaded) {
            setLoaded(true);
            return;
        }

        setSaveStatus("saving");
        const delayDebounceFn = setTimeout(async () => {
            saveChanges();
        }, 1500);
        return () => clearTimeout(delayDebounceFn);
    }, [page]);

    useEffect(() => {
        initPage();
    }, []);

    return (
        <div className="relative">
            <SaveStatus status={saveStatus} />
            <div className="mt-4 grid grid-cols-12 gap-5">
                <div className="col-span-8 rounded-xl border-2 border-[#1f2937] overflow-y-auto max-h-[500px]">
                    <div className="p-3">
                        <h1 className="text-xl md:mt-0 font-bold">
                            Rajouter vos liens
                        </h1>
                        <div key={0} className="mt-4 w-[400px]">
                            <InputField
                                label={`app.fidiz.io/page/${page?.slug}`}
                                placeholder="leburger-paris"
                                id="slug"
                                type="text"
                                value={page?.slug}
                                onChange={(e: any) => {
                                    setPage({ ...page, slug: e.target.value });
                                }}
                            />
                        </div>
                        <div className="mt-4 grid grid-cols-2 gap-4">
                            <InputField
                                label={`Titre`}
                                id="slug"
                                type="text"
                                value={page?.title}
                                onChange={(e: any) => {
                                    setPage({
                                        ...page,
                                        title: e.target.value,
                                    });
                                }}
                            />
                            <InputField
                                label={`Description`}
                                id="slug"
                                type="text"
                                value={page?.description}
                                onChange={(e: any) => {
                                    setPage({
                                        ...page,
                                        description: e.target.value,
                                    });
                                }}
                            />
                            <InputField
                                label="Instagram"
                                placeholder="leburger.paris"
                                id="instagram"
                                type="text"
                                value={page?.urls?.instagram}
                                onChange={handleUrlChange}
                            />
                            <InputField
                                label="Numéro de téléphone"
                                placeholder="06 12 34 56 78"
                                id="phone"
                                type="text"
                                value={page?.urls?.phone}
                                onChange={handleUrlChange}
                            />
                            <InputField
                                label="Adresse"
                                placeholder="06 12 34 56 78"
                                id="address"
                                type="text"
                                value={page?.urls?.address}
                                onChange={handleUrlChange}
                            />
                            <InputField
                                label="Menu"
                                placeholder="https://www.votre-restaurant.com/menu"
                                id="menu"
                                type="text"
                                value={page?.urls?.menu}
                                onChange={handleUrlChange}
                            />
                            <InputField
                                label="Uber Eats"
                                placeholder="https://www.ubereats.com/fr/votre-restaurant"
                                id="ubereats"
                                type="text"
                                value={page?.urls?.ubereats}
                                onChange={handleUrlChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-span-4 rounded-xl border-2 border-[#1f2937] overflow-y-auto h-[220px]">
                    <h1 className="p-3 text-xl md:mt-0 font-bold">
                        Tester & partager
                    </h1>
                    <QRCode
                        size={100}
                        className="mt-2 flex mx-auto p-3 border-4 mb-2 border-[#1f2937] rounded-xl"
                        value={`app.fidiz.io/page/${page?.slug}`}
                    />
                    <p
                        onClick={() =>
                            navigator.clipboard.writeText(
                                `app.fidiz.io/page/${page?.slug}`,
                            )
                        }
                        className="flex justify-center mx-auto mb-5 cursor-pointer p-2 bg-[#1f2937] text-white text-xs w-[250px] rounded-xl"
                    >
                        Copier l'url
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PageManager;
