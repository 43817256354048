import Pagination from "components/pagination/Pagination";
import spyImage from "../../assets/img/spy.png";
import rouletteImage from "../../assets/img/wheel.png";
import loyaltyImage from "../../assets/img/loyalty.png";
import gmailImage from "../../assets/img/gmail.png";
import phoneImage from "../../assets/img/phone.png";
import clientImage from "../../assets/img/client.png";
import targetImage from "../../assets/img/target.png";
import socialsImage from "../../assets/img/socials.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import GoBack from "components/actions/GoBack";

const Tools = () => {
    const [page, setPage] = useState(1);
    const items: any = [];
    const nav = useNavigate();

    const ToolCard = (props: {
        path: string;
        icon: any;
        title: string;
        description: string;
        available?: boolean;
    }) => {
        const { path, icon, title, description, available = true } = props;

        return (
            <div
                className="relative border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                onClick={() => nav(path)}
            >
                {!available && (
                    <div className="absolute right-1 top-1">
                        <p className="m-4 p-2 text-xs font-bold bg-gray-200 rounded-lg">
                            PROCHAINEMENT
                        </p>
                    </div>
                )}
                <div className="absolute right-1 top-1"></div>
                <img src={icon} alt="Data" className="w-[40px]" />
                <div>
                    <h1 className="mt-4 font-bold">{title}</h1>
                    <p className="text-sm mt-1">{description}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <div className="mt-8 md:mt-3 grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                    <h1 className="text-xl md:mt-0 font-bold">
                        Captation de données
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Utilisez nos outils pour collecter les emails et SMS de
                        vos clients pour les fidéliser
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/presentation?mode=phone"
                            icon={loyaltyImage}
                            title="Programme de fidélité"
                            description="Fidélisez vos clients grâce à notre programme de fidélité."
                        />
                        <ToolCard
                            path="/wheel-manager"
                            icon={rouletteImage}
                            title="Roue des cadeaux"
                            description="Captez des emails et SMS en permettant à vos clients de tenter de gagner un cadeau."
                        />
                    </div>
                </div>
                <div>
                    <h1 className="mt-2 text-xl md:mt-0 font-bold">
                        Marketing
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Nos outils marketing vous permettent de créer des
                        campagnes efficaces
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/email-model"
                            icon={gmailImage}
                            title="Campagnes email"
                            description="Utilisez l'éditeur et nos templates pour créer des campagnes email efficaces."
                        />
                        <ToolCard
                            path="/email-model"
                            icon={phoneImage}
                            title="Campagnes SMS"
                            description="Assurez vous d'être lu en envoyant des SMS promotionnels à vos clients."
                        />
                    </div>
                </div>
                <div>
                    <h1 className="text-xl md:mt-0 font-bold">
                        Relation client
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Restez en contact avec vos clients pour les fidéliser
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/my-customers"
                            icon={clientImage}
                            title="CRM clients"
                            description="Visualisez tout vos clients et boostez leur engagement grâce à notre CRM."
                        />
                        <ToolCard
                            path="/customers-lists"
                            icon={targetImage}
                            title="Ciblage publicitaire"
                            description="Envoyez le bon message au bon moment grâce à notre outil de ciblage publicitaire."
                        />
                    </div>
                </div>
                <div>
                    <h1 className="mt-2 text-xl md:mt-0 font-bold">
                        Veille concurrentielle
                    </h1>
                    <p className="mt-2 text-gray-600 text-sm">
                        Gardez un oeil sur vos concurrents pour mieux vous
                        adapter
                    </p>
                    <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <ToolCard
                            path="/ads"
                            icon={spyImage}
                            title="Annonces"
                            description="Vous pouvez suivre les annonces de vos concurrents grâce à notre outils de veille publicitaire."
                        />
                        <ToolCard
                            path="/ugc-creator"
                            icon={socialsImage}
                            title="Création UGC"
                            description="Inspirez vous des créations de vos concurrents et découvrez des créateurs avec qui collaborer."
                        />
                    </div>
                </div>
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={items.length}
                    itemsPerPage={[8]}
                    page={page}
                    onChange={(page: number, limit: number) => setPage(page)}
                />
            </div>
        </div>
    );
};

export default Tools;
