import { MdEuro, MdOutlineShoppingBasket } from "react-icons/md";
import { TbPigMoney, TbMoneybag } from "react-icons/tb";

import MiniStatistics from "components/card/MiniStatistics";
import { useEffect } from "react";

const CustomerStats = (props: { customer: any }) => {
    const { customer } = props;

    return (
        <div className="col-span-12 grid h-full w-full grid-cols-1 gap-3 sm:gap-5 md:grid-cols-2 xl:grid-cols-4">
            <MiniStatistics
                name="Points"
                value={customer?.points || "0"}
                icon={<TbPigMoney color="#FEDE65" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="Dernier scan"
                value={"-"}
                icon={<MdOutlineShoppingBasket color="#FEDE65" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="Dépenses"
                value={"-"}
                icon={<MdEuro color="#FEDE65" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="Retour boutique"
                value={"-"}
                icon={<TbMoneybag color="#FEDE65" />}
                iconBg="bg-lightPrimary"
            />
        </div>
    );
};

export default CustomerStats;
