import GoBack from "components/actions/GoBack";
import { createEmailModel } from "interfaces/emailModel";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

const CreateSMSModel = () => {
    const nav = useNavigate();
    const [emailModel, setEmailModel] = useState<any>({
        name: `Template du ${new Date().toLocaleDateString()} à ${new Date().toLocaleTimeString()}`,
        description: "",
        text: "20% de réduction sur votre prochain achat chez NOM DE LA MARQUE",
    });

    const saveTemplate = async () => {
        const body = {
            name: emailModel.name,
            description: emailModel.description,
            text: emailModel.text,
            type: "sms",
        };
        const res = await createEmailModel(body);
        if (res) nav("/email-model");
    };

    const handleChange = (e: any) => {
        setEmailModel({ ...emailModel, [e.target.id]: e.target.value });
    };

    return (
        <>
            <GoBack text="Choix du template" />
            <div className="mt-2 relative border-2 p-5 border-[#1f2937] rounded-xl">
                <div className="mt-3 flex justify-between">
                    <div className="flex">
                        <textarea
                            id="text"
                            className="w-[500px] h-[100px] px-4 py-2 focus:outline-none focus:ring rounded-xl border-xl border-2 border-[#1f2937] transition-colors duration-300"
                            placeholder="Contenue du SMS"
                            onChange={handleChange}
                            value={emailModel?.text}
                        ></textarea>
                    </div>
                    <div className="flex gap-4">
                        <div className="flex">
                            <input
                                id="name"
                                key={"name"}
                                type="text"
                                placeholder="Nom du template"
                                value={emailModel?.name}
                                className="p-2 w-[300px] h-[60px] rounded-xl border-xl border-2 border-[#1f2937]"
                                onChange={handleChange}
                            />
                        </div>
                        <button
                            className={`p-2 linear h-[60px] rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={saveTemplate}
                        >
                            Sauvegarder le template
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateSMSModel;
