import GoBack from "components/actions/GoBack";
import { deletePost, getContent, updatePost } from "interfaces/content";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function EditMedia() {
    const nav = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState("instagram");
    const [media, setMedia] = useState(null);
    const [text, setText] = useState("");
    const [schedule, setSchedule] = useState({ date: "", time: "" });

    useEffect(() => {
        console.log(schedule);
    }, [schedule]);

    const handlePlatformChange = (e: any) => {
        setPlatform(e.target.value);
    };

    const handleDeleteContent = async () => {
        const res = await deletePost(id);
        if (res) {
            nav("/calendar");
        }
    };

    const handlePostCreation = async () => {
        setLoading(true);
        const post = await updatePost(id, {
            platform: platform,
            caption: text,
            mediaType: "image",
            isVerified: false,
            scheduledAt: new Date(
                schedule.date + "T" + schedule.time + ":00",
            ).toISOString(),
        });
        if (post) {
            setLoading(false);
            nav("/calendar");
        }
    };

    const initContent = async () => {
        const content = await getContent(id);
        if (content) {
            if (
                content.platforms.includes("facebook") &&
                content.platforms.includes("instagram")
            ) {
                setPlatform("both");
            } else {
                setPlatform(content.platforms[0]);
            }
            setMedia(content.images[0]);
            setText(content.caption);
            const localTime = new Date(content.scheduledAt).toLocaleString();
            const time = `${localTime.split(" ")[1].split(":")[0]}:${
                localTime.split(" ")[1].split(":")[1]
            }`;
            setSchedule({
                date: content.scheduledAt.split("T")[0],
                time,
            });
        }
    };

    useEffect(() => {
        initContent();
    }, []);

    return (
        <div>
            <GoBack />
            <div className="mt-4 border-2 rounded-xl p-2">
                <h1 className="text-2xl font-bold">
                    Your post can take one minute to be published, you want to
                    check if it is online ? reload calendar and click on it !
                </h1>
                <button
                    className="p-2 bg-red-500 rounded-xl font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]"
                    onClick={() => nav("/calendar")}
                >
                    Reload Calendar
                </button>
            </div>
            <div className="mt-2 grid grid-cols-2 md:grid-cols-2 gap-4">
                {/* Left: Post Creation Form */}
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <h1 className="text-base font-bold">
                        Modifier ma publication
                    </h1>
                    {/* Multi-choise select facebook insta */}
                    <div className="mt-2 mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Plateforme
                        </label>
                        <select
                            value={platform}
                            onChange={handlePlatformChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:border-blue-300"
                        >
                            <option value="both">Facebook + Instagram</option>
                            <option value="instagram">Instagram</option>
                            <option value="facebook">Facebook</option>
                        </select>
                    </div>

                    {/* <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Télécharger un Média
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleMediaUpload}
                            className="block w-full text-sm text-fidiz-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-talk-500/90 rounded-xl hover:file:bg-blue-100"
                        />
                    </div> */}

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Écrire une Légende
                        </label>
                        <textarea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:border-blue-300"
                            rows={4}
                            placeholder="Écrivez quelque chose..."
                        />
                    </div>

                    {/* Scheduling Options */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Planifier (facultatif)
                        </label>
                        <div className="flex space-x-4">
                            <input
                                type="date"
                                value={schedule.date}
                                onChange={(e) =>
                                    setSchedule({
                                        ...schedule,
                                        date: e.target.value,
                                    })
                                }
                                className="block w-1/2 px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:border-blue-300"
                            />
                            <input
                                type="time"
                                value={schedule.time}
                                onChange={(e) =>
                                    setSchedule({
                                        ...schedule,
                                        time: e.target.value,
                                    })
                                }
                                className="block w-1/2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                    </div>
                    {loading ? (
                        <div className="mt-4 text-center">
                            <p className="text-lg text-gray-500">
                                Publication en cours...
                            </p>
                        </div>
                    ) : (
                        <>
                            <button
                                className={`w-full p-5 linear rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                                onClick={handlePostCreation}
                            >
                                Modifier le Post
                            </button>
                            <p
                                className="mt-4 text-center text-xs text-red-500 hover:cursor-pointer"
                                onClick={() => {
                                    window.confirm(
                                        "Voulez-vous vraiment annuler la publication ?",
                                    ) && handleDeleteContent();
                                }}
                            >
                                Annuler la publication
                            </p>
                        </>
                    )}
                </div>

                {/* Right: Media & Text Preview */}
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <h1 className="text-base font-bold mb-4">
                        Prévisualisation
                    </h1>
                    <div className="mx-auto border-2 border-gray-200 w-[300px] rounded-lg p-4">
                        <div className="mb-4">
                            {media ? (
                                <img
                                    src={media}
                                    alt="Media Preview"
                                    className="w-full h-64 object-cover rounded-lg"
                                />
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center text-gray-500">
                                    Aucun média sélectionné
                                </div>
                            )}
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {platform === "facebook"
                                    ? "Post Facebook"
                                    : "Post Instagram"}
                            </p>
                            <p className="mt-2 text-base text-gray-800">
                                {text || "Votre texte apparaîtra ici"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditMedia;
