import EventCalendar from "components/calendar/EventCalendar";
import { getMetaFeed } from "interfaces/content";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Calendar = () => {
    const nav = useNavigate();
    const [feed, setFeed] = useState<any[]>([]);

    const initFeed = async () => {
        const res = await getMetaFeed();
        console.log(res);
        if (res) {
            setFeed(res);
        }
    };

    useEffect(() => {
        initFeed();
    }, []);

    return (
        <div className="relative">
            {/* <div className="z-10 absolute top-10 m-auto left-0 right-0 bg-black flex items-center justify-center">
                <div className="bg-white w-[600px] py-4 rounded-lg shadow-lg text-center border-2">
                    <h3 className="text-xl font-bold mb-2">
                        Bientôt disponible
                    </h3>
                    <p className="text-base text-gray-600 px-5 ">
                        Ce calendrier vous permettra de planifier vos posts
                        facilement. Restez à l'écoute pour plus d'informations !
                    </p>
                    <button
                        className="flex mx-auto mt-4 w-[300px] text-sm px-5 h-10 linear items-center justify-center rounded-xl bg-[#518dd1] py-3 font-medium text-white cursor-pointer"
                        onClick={() => nav("/integrate")}
                    >
                        Connectez déjà vos comptes
                    </button>
                </div>
            </div> */}
            <div className="z-0">
                <button
                    className={`right-2 absolute p-5 linear rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                    onClick={() => nav("/create-media")}
                >
                    Planifier un post
                </button>
                <EventCalendar
                    initialDate={new Date().toISOString()}
                    calendarData={feed}
                />
            </div>
        </div>
    );
};

export default Calendar;
