import { useEffect, useState } from "react";
import Card from "components/card";
import MetaLogo from "assets/img/meta.png";
import GMBLogo from "assets/img/gmb.png";
import ForkLogo from "assets/img/fork.png";
import { useNavigate } from "react-router-dom";
import { connectToMeta, getMyConnections } from "interfaces/connect";
import LiveChatFooter from "components/helper/LiveChatFooter";
import CubeLoader from "components/loader/CubeLoader";
import env from "variables/config";

const Integrate = () => {
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([
        {
            name: "Meta (Facebook)",
            service: "meta",
            description:
                "Planifiez et publiez vos posts sur Facebook et Instagram.",
            logo: MetaLogo,
            status: "not-setup",
        },
        {
            name: "Google My Business",
            service: "gmb",
            description:
                "Gérez vos avis, posts et photos sur Google My Business.",
            logo: GMBLogo,
            status: "coming-soon",
        },
        {
            name: "The Fork",
            service: "fork",
            description: "Gérez vos avis, posts et photos sur The Fork.",
            logo: ForkLogo,
            status: "coming-soon",
        },
    ]);

    const handleMetaLogin = async () => {
        const facebookLoginUrl = `https://www.facebook.com/v21.0/dialog/oauth?client_id=${env.META_APP_ID}&redirect_uri=${env.META_APP_REDIRECT_URI}&scope=pages_show_list,pages_read_engagement,pages_manage_posts,instagram_content_publish,instagram_basic,business_management`;
        window.location.href = facebookLoginUrl;
    };

    const PluginCard = (props: {
        logo: any;
        name: string;
        service: string;
        description: string;
        status?: string;
        onLogin?: () => void;
    }) => {
        const { logo, name, description, service, status, onLogin } = props;
        const nav = useNavigate();

        return (
            <Card extra={"relative w-full mt-4 px-2 py-6 border"}>
                {/* Badge */}
                {status === "online" && (
                    <div className="absolute top-3 right-3 bg-green-600/80 text-white text-xs px-2 py-1 rounded-lg">
                        {"Connecté"}
                    </div>
                )}
                {status === "offline" && (
                    <div className="absolute top-3 right-3 bg-red-600/80 text-white text-xs px-2 py-1 rounded-lg">
                        {"Déconnecté"}
                    </div>
                )}
                {status === "not-setup" && (
                    <div className="absolute top-3 right-3 bg-orange-600/80 text-white text-xs px-2 py-1 rounded-lg">
                        {"Non configuré"}
                    </div>
                )}
                {/* Header */}
                <div className="w-full px-1">
                    <img
                        src={logo}
                        alt={`${name}-logo`}
                        className="ml-1 w-[70px] h-[70px] border rounded-lg"
                    />
                    <h4 className="mt-4 text-xl font-medium">{name}</h4>
                    <p className="mt-2 text-xs">{description}</p>
                </div>
                <div className="mt-4 flex justify-center">
                    {status === "coming-soon" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-[#518dd1]/70 py-3 font-medium text-white w-full cursor-not-allowed`}
                        >
                            Bientôt disponible
                        </button>
                    )}
                    {status === "not-setup" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-[#518dd1] py-3 font-medium text-white w-full cursor-pointer`}
                            onClick={onLogin}
                        >
                            Associer un compte
                        </button>
                    )}
                    {status === "online" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-[#518dd1] py-3 font-medium text-white w-full cursor-pointer`}
                            onClick={() => nav(`/integrate/${service}`)}
                        >
                            Gérer les paramètres
                        </button>
                    )}
                    {status === "offline" && (
                        <button
                            className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-[#518dd1] py-3 font-medium text-white w-full cursor-pointer`}
                            onClick={onLogin}
                        >
                            Me reconnecter
                        </button>
                    )}
                </div>
            </Card>
        );
    };

    const handleCallback = async () => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");
        if (code) {
            const res = await connectToMeta(code);
            if (res) {
                setServices((prev) =>
                    prev.map((service) =>
                        service.service === "meta"
                            ? { ...service, status: "online" }
                            : service,
                    ),
                );
                window.history.replaceState({}, document.title, "/integrate");
            }
        }
    };

    const initConnection = async () => {
        const connections = await getMyConnections();
        if (connections && connections.length > 0) {
            setServices((prev) =>
                prev.map((service) =>
                    connections.find(
                        (c: any) =>
                            c.service === service.service && c.isValid === true,
                    )
                        ? { ...service, status: "online" }
                        : service,
                ),
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        handleCallback();
        initConnection();
    }, []);

    return (
        <div>
            {loading ? (
                <div className="p-10 border-2 border-[#1f2937] rounded-xl">
                    <p className="text-2xl font-han text-center mb-[80px]">
                        Chargement des extensions...
                    </p>
                    <CubeLoader />
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                    {services.map((service) => (
                        <PluginCard
                            key={service.service}
                            name={service.name}
                            service={service.service}
                            description={service.description}
                            logo={service.logo}
                            status={service.status}
                            onLogin={handleMetaLogin}
                        />
                    ))}
                </div>
            )}
            <LiveChatFooter />
        </div>
    );
};

export default Integrate;
