import { getEmailModels } from "interfaces/emailModel";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CampaignMenu from "./component/CampaignMenu";
import LiveChatFooter from "components/helper/LiveChatFooter";
import CubeLoader from "components/loader/CubeLoader";

const colorSets = [
    {
        gradient: "from-[#d4adfe] to-[#d4adfe9b]",
        btnColor: "bg-[#d4adfe]",
    },
    {
        gradient: "from-talk-500 to-[#fedd6597]",
        btnColor: "bg-talk-500",
    },
    {
        gradient: "from-[#f1e9f8] to-[#f1e9f8b2]",
        btnColor: "bg-[#f1e9f8]",
    },
];

const ListEmailModel = (props: {
    logo: any;
    key: string;
    name: string;
    service: string;
    description: string;
    url: string;
    gradient: string;
    btnColor: string;
    type: string;
}) => {
    const { name, key, url, gradient, btnColor, type } = props;
    const nav = useNavigate();

    return (
        <div className="relative w-full mt-2 shadow-lg border-2 border-[#1f2937] rounded-xl">
            <div className="absolute top-3 right-3 bg-[#1f2937] text-white text-xs px-2 py-1 rounded-lg">
                <p
                    className="font-han text-[8px] cursor-pointer"
                    onClick={() => {
                        navigator.clipboard.writeText(key);
                        toast.info("ID copié");
                    }}
                >
                    {type}
                </p>
            </div>
            <div
                className={`px-2 flex border min-h-[170px] bg-gradient-to-r ${gradient} rounded-t-lg flex items-center justify-center`}
            >
                <p className="my-10 align-middle text-center text-lg">{name}</p>
            </div>
            <div className="w-full px-4">
                <button
                    className={`mb-4 linear mt-6 w-full rounded-xl ${btnColor} border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:opacity-70 active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                    onClick={() => nav(url)}
                >
                    Modifier le template
                </button>
            </div>
        </div>
    );
};

const Marketing = () => {
    const nav = useNavigate();
    const [emailModels, setEmailModels] = useState(null);

    const initEmailModels = async () => {
        const res = await getEmailModels();
        if (res) setEmailModels(res.data);
    };

    useEffect(() => {
        initEmailModels();
    }, []);

    return (
        <div className="">
            {emailModels && emailModels.length > 0 && (
                <div className="flex justify-between">
                    <div className="mt-2">
                        <CampaignMenu path="/email-model" />
                    </div>
                    <button
                        className={`p-5 linear rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                        onClick={() => nav("/template/new")}
                    >
                        Ajouter un template
                    </button>
                </div>
            )}
            {/* No email Model created */}
            {emailModels && emailModels.length === 0 && (
                <>
                    <div className="flex justify-between">
                        <div className="mt-2">
                            <CampaignMenu path="/email-model" />
                        </div>
                    </div>
                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-han text-center">
                            Mettez en place vos premiers templates d'email !
                        </p>
                        <p className="flex mx-auto mt-5 text-center max-w-[550px]">
                            Préparez votre email grâce à l'éditeur drag & drop.
                            Une fois terminé vous pouvez l'associer à une
                            campagne pour l'envoyer à vos clients cibles
                        </p>
                        <button
                            className={`mt-5 flex mx-auto p-5 linear rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-sm transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={() => nav("/email-model/new")}
                        >
                            Commencer avec mon premier template
                        </button>
                    </div>
                </>
            )}
            {/* List email models */}
            {emailModels && emailModels.length > 0 && (
                <div className="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {emailModels &&
                        emailModels.map((emailModel: any, index: number) => (
                            <ListEmailModel
                                key={emailModel._id}
                                name={emailModel.name}
                                service={emailModel.service}
                                description={emailModel.description}
                                logo={emailModel.logo}
                                type={emailModel.type}
                                url={
                                    emailModel.type === "email"
                                        ? `/email-model/${emailModel._id}`
                                        : `/sms-model/${emailModel._id}`
                                }
                                gradient={colorSets[index % 3].gradient}
                                btnColor={colorSets[index % 3].btnColor}
                            />
                        ))}
                </div>
            )}
            {/* Loading */}
            {!emailModels && (
                <>
                    <div className="flex justify-between">
                        <div className="mt-2">
                            <CampaignMenu path="/email-model" />
                        </div>
                    </div>

                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-han text-center mb-[80px]">
                            Chargement des templates...
                        </p>
                        <CubeLoader />
                    </div>
                </>
            )}
            <LiveChatFooter />
        </div>
    );
};

export default Marketing;
