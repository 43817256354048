import GoBack from "components/actions/GoBack";
import Select from "components/fields/Select";
import LiveChatFooter from "components/helper/LiveChatFooter";
import { createCampaign } from "interfaces/campaign";
import { getEmailModels } from "interfaces/emailModel";
import { getTargets } from "interfaces/target";
import { useEffect, useState } from "react";
import { IoOpenOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateCampaign = () => {
    const nav = useNavigate();
    const [customerTargets, setCustomerTargets] = useState<any>([]);
    const [emailModels, setEmailModels] = useState([]);

    const [total, setTotal] = useState(0);

    const [newCampaign, setNewCampaign] = useState<any>({
        template: "",
        target: "",
    });

    const saveTemplate = async () => {
        const res = await createCampaign(newCampaign);
        if (res.insufficientTokens) {
            toast.error(
                "Vous n'avez pas assez de tokens pour lancer la campagne",
            );
            nav("/tokens");
        } else {
            nav("/marketing");
        }
    };

    const initCustomersTargets = async () => {
        const res = await getTargets();
        if (res) {
            const targetOptions = res.data.map((target: any) => {
                return {
                    ...target,
                    value: target._id,
                    label: target.name,
                    id: target.id,
                };
            });
            setCustomerTargets(targetOptions);
            if (targetOptions.length > 0) {
                setTotal(targetOptions[0].totalCustomers);
            }
        }
    };

    const initEmailModels = async () => {
        const res = await getEmailModels();
        if (res) {
            const emailsModelsOptions = res.data.map((emailModel: any) => {
                return {
                    ...emailModel,
                    value: emailModel._id,
                    label: emailModel.name,
                    id: emailModel.id,
                };
            });
            setEmailModels(emailsModelsOptions);
        }
    };

    useEffect(() => {
        if (!newCampaign.target) {
            setNewCampaign({
                ...newCampaign,
                target: customerTargets[0]?.value,
            });
        }
        if (!newCampaign.template) {
            setNewCampaign({
                ...newCampaign,
                template: emailModels[0]?.value,
            });
        }
        console.log(newCampaign);
    }, [emailModels, customerTargets, newCampaign]);

    useEffect(() => {
        initCustomersTargets();
        initEmailModels();
    }, []);

    return (
        <>
            <GoBack text="Mes campagnes" />
            <div className="mt-2 relative border-2 p-5 border-[#1f2937] rounded-xl">
                <div className="grid grid-cols-1 lg:grid-cols-2 ">
                    <div>
                        <div className="flex gap-4">
                            <p className="text-lg font-han">
                                1 - Template de ma campagne
                            </p>
                        </div>
                        <p className="mt-2 ml-1 text-sm">
                            Choisissez le template email ou SMS envoyé pendant
                            cette campagne
                        </p>
                        <div className="mt-3 flex gap-3">
                            <Select
                                key={"template"}
                                options={emailModels}
                                displayKey="label"
                                onSelect={(e: any) =>
                                    setNewCampaign({
                                        ...newCampaign,
                                        template: e.value,
                                    })
                                }
                                value={newCampaign.template}
                                required={false}
                            />
                            <div
                                className="flex gap-3 my-auto"
                                onClick={() =>
                                    window.open("/email-model", "_blank")
                                }
                            >
                                <p className="flex gap-1 text-xs font-medium text-blue-600 cursor-pointer">
                                    Gérer mes templates
                                    <IoOpenOutline
                                        className="flex my-auto"
                                        size={10}
                                    />
                                </p>
                            </div>
                        </div>
                        <p className="ml-3 mt-1 text-xs">
                            Type de campagne:{" "}
                            {
                                emailModels?.find(
                                    (e) => e._id === newCampaign.template,
                                )?.type
                            }
                        </p>
                    </div>
                    <div className="mt-5 lg:mt-0">
                        <div className="flex gap-4">
                            <p className="text-lg font-han">
                                2 - Ciblage client
                            </p>
                        </div>
                        <p className="mt-2 ml-1 text-sm">
                            Choisissez les clients qui recevront cette campagne
                        </p>
                        <div className="mt-3 flex gap-3">
                            {customerTargets.length > 0 && (
                                <Select
                                    key={"target"}
                                    options={customerTargets}
                                    displayKey="label"
                                    onSelect={(e: any) => {
                                        setNewCampaign({
                                            ...newCampaign,
                                            target: e.value,
                                        });
                                        setTotal(e.totalCustomers);
                                    }}
                                    value={newCampaign.target}
                                    required={false}
                                />
                            )}
                            <div
                                className="flex gap-3 my-auto"
                                onClick={() =>
                                    window.open("/customers-lists", "_blank")
                                }
                            >
                                <p className="flex gap-1 text-xs font-medium text-blue-600 cursor-pointer">
                                    Gérer mes listes clients
                                    <IoOpenOutline
                                        className="flex my-auto"
                                        size={10}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-10 flex col-span-1 lg:col-span-2 gap-2 justify-center">
                    <input
                        id="object"
                        key={"object"}
                        type="text"
                        placeholder={
                            emailModels?.find(
                                (e) => e._id === newCampaign.template,
                            )?.type === "email"
                                ? "Objet de l'email"
                                : "Nom de la campagne"
                        }
                        value={newCampaign.object}
                        className="p-2 w-[200px] h-[40px] rounded-xl border-xl border-2 border-[#1f2937]"
                        onChange={(e) =>
                            setNewCampaign({
                                ...newCampaign,
                                object: e.target.value,
                            })
                        }
                    />
                    <button
                        className={`p-2 linear h-[40px] rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-2 text-md transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                        onClick={saveTemplate}
                    >
                        Lancer la campagne pour {total} clients
                    </button>
                </div>
                <p className="mt-2 text-md text-center">
                    Coût de la campagne:{" "}
                    {emailModels?.find((e) => e._id === newCampaign.template)
                        ?.type === "email"
                        ? "0 tokens"
                        : ` ${total} tokens`}
                </p>
            </div>
            <LiveChatFooter />
        </>
    );
};

export default CreateCampaign;
