import GoBack from "components/actions/GoBack";
import { createPost, uploadMedia } from "interfaces/content";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function PostScheduler() {
    const nav = useNavigate();
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState("instagram");
    const [media, setMedia] = useState(null);
    const [file, setFile] = useState(null);
    const [text, setText] = useState("");
    const [error, setError] = useState("");
    const [schedule, setSchedule] = useState({ date: "", time: "" });

    const handleMediaUpload = (e: any) => {
        const file = e.target.files[0];

        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const width = img.width;
                const height = img.height;
                const aspectRatio = width / height;

                console.log(1.91, aspectRatio, 4 / 5);
                if (aspectRatio >= 0.8 && aspectRatio <= 1.91) {
                    setMedia(URL.createObjectURL(file));
                    setFile(file);
                    setError("");
                } else {
                    setError("L'image doit être entre 1.91:1 et 4:5.");
                }
            };
        }
    };

    const handlePlatformChange = (e: any) => {
        setPlatform(e.target.value);
    };

    const handlePostCreation = async () => {
        if (!file) {
            setError("Veuillez sélectionner un média.");
            return;
        }
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        const media = await uploadMedia(formData);
        if (media) {
            const post = await createPost({
                platform: platform,
                images: [media.data.key],
                caption: text,
                mediaType: "image",
                isVerified: false,
                scheduledAt: new Date(
                    schedule.date + "T" + schedule.time + ":00",
                ).toISOString(),
            });
            if (post) {
                setLoading(false);
                nav("/calendar");
            }
        }
    };

    useEffect(() => {
        // const tomorrow = new Date();
        // tomorrow.setDate(tomorrow.getDate() + 1);
        // tomorrow.setHours(18);
        // tomorrow.setMinutes(0);
        // setSchedule({
        //     date: tomorrow.toISOString().split("T")[0],
        //     time: "18:00",
        // });
        const today = new Date();
        setSchedule({
            date: today
                .toLocaleString()
                .split(" ")[0]
                .split("/")
                .reverse()
                .join("-"),
            time: today
                .toLocaleString()
                .split(" ")[1]
                .split(":")
                .slice(0, 2)
                .join(":"),
        });
    }, []);

    return (
        <div>
            <GoBack />
            <div className="mt-2 grid grid-cols-2 md:grid-cols-2 gap-4">
                {/* Left: Post Creation Form */}
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <h1 className="text-base font-bold">
                        Créer une publication
                    </h1>
                    {/* Multi-choise select facebook insta */}
                    <div className="mt-2 mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Plateforme
                        </label>
                        <select
                            value={platform}
                            onChange={handlePlatformChange}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:border-blue-300"
                        >
                            <option value="both">Facebook + Instagram</option>
                            <option value="instagram">Instagram</option>
                            <option value="facebook">Facebook</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Télécharger un Média
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleMediaUpload}
                            className="block w-full text-sm text-fidiz-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-talk-500/90 rounded-xl hover:file:bg-blue-100"
                        />
                        <p className="text-xs text-red-500 mt-2">{error}</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Écrire une Légende
                        </label>
                        <textarea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            className="block w-full px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:border-blue-300"
                            rows={4}
                            placeholder="Écrivez quelque chose..."
                        />
                    </div>

                    {/* Scheduling Options */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-medium mb-2">
                            Planifier (facultatif)
                        </label>
                        <div className="flex space-x-4">
                            <input
                                type="date"
                                value={schedule.date}
                                onChange={(e) =>
                                    setSchedule({
                                        ...schedule,
                                        date: e.target.value,
                                    })
                                }
                                className="block w-1/2 px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring focus:border-blue-300"
                            />
                            <input
                                type="time"
                                value={schedule.time}
                                onChange={(e) =>
                                    setSchedule({
                                        ...schedule,
                                        time: e.target.value,
                                    })
                                }
                                className="block w-1/2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                    </div>
                    {loading ? (
                        <div className="mt-4 text-center">
                            <p className="text-lg text-gray-500">
                                Publication en cours...
                            </p>
                        </div>
                    ) : (
                        <button
                            className={`w-full p-5 linear rounded-xl bg-gradient-to-r from-talk-500 to-[#fedd6597] border-2 border-[#1f2937] font-han py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={handlePostCreation}
                        >
                            Planifier le Post
                        </button>
                    )}
                </div>

                {/* Right: Media & Text Preview */}
                <div className="col-span-1 bg-white shadow-lg rounded-lg p-4 border">
                    <h1 className="text-base font-bold mb-4">
                        Prévisualisation
                    </h1>
                    <div className="mx-auto border-2 border-gray-200 w-[300px] rounded-lg p-4">
                        <div className="mb-4">
                            {media ? (
                                <img
                                    src={media}
                                    alt="Media Preview"
                                    className="w-full h-64 object-cover rounded-lg"
                                />
                            ) : (
                                <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center text-gray-500">
                                    Aucun média sélectionné
                                </div>
                            )}
                        </div>
                        <div>
                            <p className="text-sm font-medium text-gray-500">
                                {platform === "facebook"
                                    ? "Post Facebook"
                                    : "Post Instagram"}
                            </p>
                            <p className="mt-2 text-base text-gray-800">
                                {text || "Votre texte apparaîtra ici"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostScheduler;
